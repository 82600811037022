import React, { useEffect } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

import { XMarkIcon } from '@heroicons/react/24/solid';

const CustomArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{ ...style, display: 'hidden', background: 'transparent', borderRadius: '50%' }}
    onClick={onClick}
  />
);

const PopupCarousel = ({ project, onClose }) => {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    className: 'px-1',
    customPaging: i => (
      <div
        style={{
          marginTop: "10px",
          width: "10px",
          height: "10px",
          marginRight: "5px",
          borderRadius: "50%",
          backgroundColor: "#FCFCFC",
        }}
      />
    )
  };

  useEffect(() => {
    document.body.classList.add('overflow-hidden');
    return () => {
      document.body.classList.remove('overflow-hidden');
    };
  }, []);

  return (
    <div className="popup-carousel fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-90 h-full">
      <style>
        {`
          .popup-carousel .slick-prev, 
          .popup-carousel .slick-next {
            width: 30px;
            height: 30px;
            z-index: 1;
          }
          
          .popup-carousel .slick-prev:before, 
          .popup-carousel .slick-next:before {
            font-size: 30px;
            color: white;
          }

          @media (max-width: 890px) {
            .popup-carousel .slick-prev, 
            .popup-carousel .slick-next {
              top: 50%;
              transform: translateY(50%);
              transform: translateX(50%)
            }

            .popup-carousel .slick-prev {
              display: none;
            }

            .popup-carousel .slick-next {
              display: none;
            }
          }
        `}
      </style>
      <div className="bg-transparent lg:p-4 md:p-10 sm:p-12 mt-64 rounded relative lg:max-w-6xl md:max-w-4xl md:mb-[40px] lg:mb-[12px] max-w-6xl w-full h-full">
        <div onClick={onClose}>
          <XMarkIcon className="size-11 text-[#FCFCFC] absolute lg:top-[108px] lg:right-[56px] md:top-[128px] md:right-[72px] top-4 right-5 z-10 bg-[#414141] rounded-full p-2" />
          &times;
        </div>
        <h2 className="sm:text-2xl text-xl md:mb-4 mb-5 text-white ml-4 my-auto w-[80%] sm:max-full">{project.titulo}</h2>
        <Slider {...settings}>
          {project.imgGallery.map((img, index) => (
            <div key={index} className="h-[350px] w-[300px] lg:h-[600px] md:h-[500px] sm:h-[400px] flex justify-center items-center">
              <img 
                src={img} 
                alt={`${project.titulo} ${index + 1}`} 
                className="object-cover h-full w-full" 
              />
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
};

export default PopupCarousel;
