import React from 'react';
import brandLogo from '../assets/BrandLogo.svg';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-5">
      <div className="relative inset-x-0 top-0 h-[20px] bg-[#295186] z-20"></div>
      <div className="container mx-auto px-4">
        <div className="flex flex-wrap justify-between pt-10">
          <div className="w-full sm:w-1/4 mb-6 text-center sm:text-left">
          <a 
          title="a-brand"
          href="#home">
            <img
              src={brandLogo}
              alt="Brand Logo"
              title="edici-logo"
              className="w-24 md:w-32 lg:w-40 mb-5 mx-auto sm:mx-0 select-none"
            />
            </a>
            <p className="text-gray-400 mb-5 select-none">
              Ofrecemos servicios integrales que aseguran la precisión, eficiencia y sostenibilidad de cada proyecto.
            </p>
            <div className="flex justify-center sm:justify-start space-x-4 mt-4">
              <a title='footer-ig' href="#home" className="text-gray-400 hover:text-white">
                <i className="fab fa-instagram"></i>
              </a>
              <a title='footer-fb' href="#home" className="text-gray-400 hover:text-white">
                <i className="fab fa-facebook"></i>
              </a>
              <a title='footer-linkedin' href="#home" className="text-gray-400 hover:text-white">
                <i className="fab fa-linkedin"></i>
              </a>
            </div>
          </div>


          <div className="w-full sm:w-1/4"></div>


          <div className="w-full sm:w-1/4 text-center md:text-left">
            <h2 className="sm:ml-4 text-lg font-semibold md:mb-0 -mb-[10px] select-none">Indice</h2>
            <ul className="-space-y-8 md:-space-y-7">
              <li>
                <a title='footer-inicio' href="#home" className="text-gray-400 hover:text-white">
                  Inicio
                </a>
              </li>
              <li>
                <a title='footer-aboutus' href="#AboutUs" className="text-gray-400 hover:text-white">
                  Nosotros
                </a>
              </li>
              <li>
                <a title='footer-servicios' href="#Services" className="text-gray-400 hover:text-white">
                  Servicios
                </a>
              </li>
              <li>
                <a title='footer-metBim' href="#MetBim" className="text-gray-400 hover:text-white">
                  Metodología BIM
                </a>
              </li>
              <li>
                <a title='footer-contact' href="#Contact" className="text-gray-400 hover:text-white">
                  Contacto
                </a>
              </li>
            </ul>
          </div>

          <div className="w-full sm:w-1/4 text-center md:text-left">
            <h2 className="sm:ml-4 text-lg font-semibold md:mb-0 -mb-[10px] select-none">Datos de contacto</h2>
            <ul className="-space-y-8 md:-space-y-7">
              <li>
                <a title='footer-wsp' href="https://wa.me/+543434387343?text=Hola%20quiero%20hacer%C2%A0una%C2%A0consulta" data-clickid='footer-wsp' className="text-gray-400 hover:text-white">
                  +54 9 3434 38-7343
                </a>
              </li>
              <li>
                <a title='footer-email' href="#Contact" className="text-gray-400 hover:text-white">
                  edici.ingenieria@gmail.com
                </a>
              </li>
              <li>
                <a title='footer-location' href="https://maps.app.goo.gl/N5tqTDuvvW6sfSkk7" target="_blank" rel="noopener noreferrer" className="text-gray-400 hover:text-white">
                  Paraná, Entre Ríos, Argentina
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="border-t border-gray-700 mt-6 pt-4 flex flex-wrap md:justify-between justify-center text-gray-400 text-sm">
          <span className='select-none'>© Todos los derechos reservados por EDICI</span>
          <a title='footer-iconstudio' href="https://www.iconstudio.com.ar/" target="_blank" rel="noopener noreferrer" className='hover:text-white'>Diseño UX/UI desarrollado por Icon Studio</a>
          <a title='footer-nubeads' href="https://nubeads.com/" target="_blank" rel="noopener noreferrer" className='hover:text-white'>Coded por Nube Ads</a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
