import React, { useState } from 'react';
import { MdArrowForward, MdArrowBack } from "react-icons/md";
import { motion } from 'framer-motion';

const Hero = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const iframes = [
    "https://hotmail490219.autodesk360.com/shares/public/SH286ddQT78850c0d8a4ec60838a37094a95?mode=embed",
    "https://hotmail490219.autodesk360.com/shares/public/SH286ddQT78850c0d8a49a8fa9ef616f2bf5?mode=embed", // Cambia estos enlaces por los iframes reales
    "https://hotmail490219.autodesk360.com/shares/public/SH286ddQT78850c0d8a4ada1779f80078944?mode=embed",
    "https://hotmail490219.autodesk360.com/shares/public/SH286ddQT78850c0d8a440bbf320b37c2f10?mode=embed"
  ];

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? iframes.length - 1 : prevIndex - 1));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === iframes.length - 1 ? 0 : prevIndex + 1));
  };

  return (
    <div id='home' className='bg-[#00040D] sm:mb-0 bg-[url("assets/Background-home.jpg")] bg-cover bg-center bg-no-repeat sm:h-[1024px] h-[1280px] xl:px-[128px] px-0 sm:px-5 md:px-5 lg:px-10 w-full text-white relative z-0 overflow-hidden'>
      <div className="h-full flex justify-center items-center sm:pt-[128px] pt-0">
        <div className="px-5 sm:px-20 grid grid-cols-1 lg:grid-cols-3">
          <div className='select-none space-y-6 lg:space-y-10 lg:pr-36 col-span-2'>
            <motion.h1 initial={{opacity: 0, y:-20}}
                        whileInView={{opacity: 1, y:0}}
                        viewport={{once: true}}
                        transition={{delay: 0.4, stiffness: 100, type:'tween'}}
            className='text-4xl md:text-5xl 2xl:text-6xl pt-0 sm:mt-0 -mt-12 md:pt-0 lg:pt-20 xl:pt-28 2xl:pt-32 font-bold uppercase text-center sm:text-left'>
              INGENIERÍA ESTRUCTURAL METODOLOGÍA BIM
            </motion.h1>
            <motion.p initial={{opacity: 0, y:-20}}
                        whileInView={{opacity: 1, y:0}}
                        viewport={{once: true}}
                        transition={{delay: 0.5, stiffness: 100, type:'tween'}}
            className='m-auto md:text-l 2xl:text-xl sm:w-[80%] w-full font-light text-center sm:text-left sm:m-0'>
              Apoyamos a estudios de arquitectura y empresas constructoras con cálculos 
              estructurales precisos y metodologías BIM avanzadas para garantizar proyectos 
              exitosos y sostenibles.
            </motion.p>
            <div className='pt-5 sm:pt-0 ml-3'>
                <div className='flex'>
              <a title='hero-contact' href="#Contact" className='active:scale-95 transform'>
              <motion.button 
                        initial={{opacity: 0, y:-20}}
                        whileInView={{opacity: 1, y:0}}
                        viewport={{once: true}}
                        transition={{delay: 0.7, stiffness: 100, type:'tween'}}
              className='bg-[#57AFDF] text-[#FCFCFC] py-[15px] px-6 font-medium rounded-md flex justify-center text-xl 
              ml-auto mt-auto mb-auto md:mr-3 sm:m-0 
              hover:bg-[#295186]'>
                CONSULTAR
              </motion.button>
              </a>
              <a title='hero-wsp' target="_blank" rel="noreferrer" href='https://wa.me/+543434387343?text=Hola%20quiero%20hacer%C2%A0una%C2%A0consulta' id='hero-wsp' className='active:scale-95 transform'>
              <motion.button 
                        initial={{opacity: 0, y:-20}}
                        whileInView={{opacity: 1, y:0}}
                        viewport={{once: true}}
                        transition={{delay: 0.9, stiffness: 100, type:'tween'}}
              className='bg-[#25cc64] text-[#FCFCFC] py-[15px] px-6 font-medium 
              rounded-md flex justify-center text-xl md:m-auto sm:m-0 ml-5
              hover:bg-[#339458] active:transform active:scale-95'>
                WHATSAPP
              </motion.button>
              </a>
              </div>
            </div>
          </div>
          <div className='relative sm:h-[670px] m-auto pt-20 lg:pt-0 lg:-ml-10 xl:ml-0 z-20'>
          <motion.div
          initial={{opacity:0}}
          animate={{opacity:1}}
          viewport={{once: true}}
          transition={{delay: 0.7, stiffness: 100, type:'tween'}}
          className="absolute lg:bottom-[116px] xl:-bottom-4 -left-3 sm:bottom-[40px] -bottom-3 w-32 sm:h-56 h-44 bg-[#57AFDF] rounded-xl -z-10"></motion.div>

          <motion.div
                    initial={{opacity:0}}
                    animate={{opacity:1}}
                    viewport={{once: true}}
                    transition={{delay: 0.7, stiffness: 100, type:'tween'}}
           className="absolute lg:-top-3 -right-3 md:top-[68px] top-[68px] w-32 sm:h-56 h-44 bg-[#57AFDF] rounded-xl -z-10"></motion.div>

  <motion.iframe 
    initial={{opacity: 0, y:-20}}
    animate={{opacity: 1, y:0}}
    transition={{delay: 0.5, stiffness: 100, type:'tween'}}
    src={iframes[currentIndex]}
    title='steelframe'
    webkitallowfullscreen="true" 
    mozallowfullscreen="true" 
    frameBorder="0" 
    className="rounded-xl xl:w-[500px] xl:h-[675px] sm:w-[400px] sm:h-[540px] w-[300px] h-[560px] bg-[#FCFCFC]"
  ></motion.iframe>
  <button 
    onClick={handlePrev} 
    className='absolute left-0 top-1/2 md:transform md:-translate-y-1/2 p-2 bg-[#00040D] text-[#FCFCFC] ml-2 rounded-full'
    style={{ top: '50%', left: '-3rem' }}
  >
    <MdArrowBack />
  </button>
  <button 
    onClick={handleNext} 
    className='absolute right-0 top-1/2 md:transform md:-translate-y-1/2 p-2 bg-[#00040D] text-[#FCFCFC] mr-2 rounded-full'
    style={{ top: '50%', right: '-3rem' }}
  >
    <MdArrowForward />
  </button>
</div>
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 h-[160px] bg-gradient-to-t from-[#00040D] to-transparent -z-20"></div>
    </div>
  );
}

export default Hero;
