import React, { useEffect, useState } from 'react';
import whatsapp from '../assets/whatsapp_button2.svg';
import '../styles.css';

const WhatsAppButton = () => {
  const [isBottom, setIsBottom] = useState(false);

  useEffect(() => {
    const handleScroll = () => {

      const isAtBottom =
        window.innerHeight + window.scrollY >= document.body.offsetHeight - 50;
      setIsBottom(isAtBottom);
    };


    window.addEventListener('scroll', handleScroll);


    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <a
      title='wsp-button-main'
      href="https://wa.me/+543434387343?text=Hola%20quiero%20hacer%C2%A0una%C2%A0consulta" 
      target="_blank"
      rel="noopener noreferrer"
      className={`fixed bottom-4 right-4 z-50 ${
        isBottom ? 'mb-16' : 'mb-2'
      }`} 
      data-clickid="whatsappButtonFloat"
    >
      <img
        src={whatsapp}
        alt="WhatsApp"
        title="WhatsApp-button"
        className="sm:w-20 sm:h-20 h-16 w-16 animate-bounce sm:mr-10 sm:mb-3 mr-1"
        data-clickid="whatsappButtonFloatImg"
      />
    </a>
  );
};

export default WhatsAppButton;
