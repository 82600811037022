import React, { useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import DoralTigre from '../assets/Doral.jpg';
import Pileta from '../assets/Popupcarrusel/doral/pileta.jpg';
import Render3d from '../assets/Popupcarrusel/doral/render3d.jpg';
import Shopping from '../assets/Popupcarrusel/doral/shopping.jpg';

import SteelFrame from '../assets/steelframe.png';
import contraSteel from '../assets/Popupcarrusel/steelframe/contrafrente.jpg';
import frenteSteel from '../assets/Popupcarrusel/steelframe/frente.jpg';
import obraSteel from '../assets/Popupcarrusel/steelframe/obra.jpg';

import NicolasLaberti from '../assets/NicolasLaberti.jpg';
import contraSpinelli from '../assets/Popupcarrusel/spinelli/contrafrente.jpg';
import estructuraSpinelli from '../assets/Popupcarrusel/spinelli/estructura.spinelli.jpg';
import obraSpinelli from '../assets/Popupcarrusel/spinelli/obra.jpeg';

import { motion } from 'framer-motion';


import PopupCarousel from './PopupCarousel'; 
import verIcon from '../assets/lupita.svg';

const proyectos = [
  {
    titulo: "Proyecto Doral - Tigre, Bs. As.",
    estado: "Estado: En proceso",
    arquitecto: "Arq. Francisco Sanchez Arjona",
    ingeniero: "Ing. Edici SAS",
    constructor: "",
    imgSrc: DoralTigre,
    imgGallery: [ 
      Pileta,
      Render3d,
      Shopping,
    ],
  },
  {
    titulo: "Proyecto Steel Frame - Concordia, E.R",
    estado: "Estado: En proceso",
    arquitecto: "Arq. María E. Salinas",
    ingeniero: "Ing. Edici SAS",
    constructor: "Constructor: Tecnosteel SAS",
    imgSrc: SteelFrame,
    imgGallery: [ 
      contraSteel,
      frenteSteel,
      obraSteel,
    ],
  },
  {
    titulo: "Proyecto Nicolás Lamberti - Pilar, Bs. As.",
    estado: "Estado: Terminado",
    arquitecto: "Arq. Nicolás A. Lamberti",
    ingeniero: "Ing. Edici SAS",
    constructor: "",
    imgSrc: NicolasLaberti,
    imgGallery: [ 
      contraSpinelli,
      estructuraSpinelli,
      obraSpinelli,
    ],
  },
];

const CustomArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{ ...style, display: 'hidden', background: 'transparent', borderRadius: '50%' }}
    onClick={onClick}
  />
);

const FeaturedProyects = () => {
  const [activeProject, setActiveProject] = useState(null);

  const handleOpenPopup = (proyecto) => {
    setActiveProject(proyecto);
  };

  const handleClosePopup = () => {
    setActiveProject(null);
  };
  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          dots: true,
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          dots: true,
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 996,
        settings: {
          dots: true,
          arrows: true,
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    customPaging: i => (
      <div
        style={{
          width: "10px",
          height: "10px",
          borderRadius: "50%",
          backgroundColor: "#FCFCFC",
        }}
      />
    )
  };

  return (
    <div id="FeaturedProyects" className='proyects-carousel bg-black bg-[url("assets/background_proyectos.jpg")] bg-cover bg-center bg-no-repeat h-[1024px] max-w-screen xl:px-[128px] px-0 sm:px-5 md:px-5 lg:px-10 relative z-0'>
                    <style>
        {`
          .proyects-carousel .slick-prev, 
          .proyects-carousel .slick-next {
            width: 30px;
            height: 30px;
            z-index: 1;
          }
          
          .proyects-carousel .slick-prev:before, 
          .proyects-carousel .slick-next:before {
            font-size: 30px;
            color: white;
          }

          @media (max-width: 890px) {
            .proyects-carousel .slick-prev, 
            .proyects-carousel .slick-next {
              top: 50%;
              transform: translateY(180%);
            }

            .proyects-carousel .slick-prev {
              left: -20px;
            }

            .proyects-carousel .slick-next {
              right: -20px;
            }

          }
        `}
      </style>
    <div className="sm:px-20 px-5 bg-transparent mx-auto sm:py-8 py-0">
      <motion.h2 
            initial={{opacity: 0, y:-20}}
            whileInView={{opacity: 1, y:0}}
            viewport={{once: true}}
            transition={{delay: 0.4, stiffness: 100, type:'tween'}}
      className="text-6xl font-bold mb-6 text-center sm:pt-18 pt-16 select-none">Proyectos Destacados</motion.h2>
      <motion.p 
                  initial={{opacity: 0, y:-20}}
                  whileInView={{opacity: 1, y:0}}
                  viewport={{once: true}}
                  transition={{delay: 0.5, stiffness: 100, type:'tween'}}
      className="text-center sm:pb-36 max-w-[650px] text-2xl m-auto leading-tight select-none">
      Expertos en precisión y soluciones integrales.
      </motion.p>
      <Slider {...settings}>
        {proyectos.map((proyecto, index) => (
          <motion.div 
          initial={{opacity: 0, y:-20}}
          whileInView={{opacity: 1, y:0}}
          viewport={{once: true}}
          transition={{delay: 0.6, stiffness: 100, type:'tween'}}
          key={index} className="p-5 pt-32 sm:pt-0 w-[539px] min-h-[450px] sm:h-[450px] h-[600px]">
            <div 
              className="bg-gray-800 overflow-hidden shadow-lg h-full w-full flex flex-col justify-end relative"
              style={{
                backgroundImage: `url(${proyecto.imgSrc})`,
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              <div className="bg-transparent p-4 text-[#FCFCFC] z-20 flex-grow flex flex-col justify-start max-h-[150px]">
                <h3 className="text-xl font-semibold">{proyecto.titulo}</h3>
                <p>{proyecto.estado}</p>
                <p>{proyecto.arquitecto}</p>
                <p>{proyecto.ingeniero}</p>
                <p>{proyecto.constructor}</p>
                <button
                  className="text-white p-2 rounded absolute top-2 right-3"
                  onClick={() => handleOpenPopup(proyecto)}
                >
                  <img src={verIcon} alt="ver" title="lupa-button" className='h-10 w-10 transform hover:scale-110'></img>
                </button>
              </div>
            </div>
            <div className="absolute inset-x-0 bottom-0 h-[200px] bg-gradient-to-t from-black opacity-75 to-transparent z-10"></div>
          </motion.div>
        ))}
      </Slider>
      {activeProject && (
        <PopupCarousel
          project={activeProject}
          onClose={handleClosePopup}
        />
      )}
    </div>
    <div className="absolute inset-x-0 bottom-0 h-[160px] bg-gradient-to-t from-black to-transparent -z-10"></div>
    </div>
  );
};

export default FeaturedProyects;
