import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import carruselPhotoGerman from '../assets/German_foto.jpg';
import carruselPhotoMaga from '../assets/Magali_foto.jpg';
import { FaLinkedin } from "react-icons/fa";
import { motion } from 'framer-motion';
    


const teamMembers = [
  {
    name: 'Germán Reisenauer',
    title: 'Ingeniero Civil',
    education: 'Maestría en Administración de empresa.',
    linkedin: 'https://www.linkedin.com/in/germ%C3%A1n-reisenauer-b601aa174/',
    image: carruselPhotoGerman,
  },
  {
    name: 'Magalí Londero',
    title: 'Ingeniera Civil',
    education: 'Maestría en Administración de empresa.',
    linkedin: 'https://www.linkedin.com/in/magalilondero/',
    image: carruselPhotoMaga,
  },
];

const CustomArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{ ...style, display: 'hidden', background: 'transparent', borderRadius: '50%' }}
    onClick={onClick}
  />
);

const AboutUs = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 640,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          className: "center",
          centerPadding: "25px",
        },
      },
    ],
  };

  return (
    <div id="AboutUs" className='aboutUs-carousel bg-[#00040D] bg-[url("assets/Fondo-Sobre-Nosotros.jpg")] bg-cover bg-center bg-no-repeat sm:h-[1024px] h-[1124px] xl:px-[128px] px-0 sm:px-5 md:px-5 lg:px-10 w-full relative z-0 overflow-hidden'>
                <style>
        {`
          .aboutUs-carousel .slick-prev, 
          .aboutUs-carousel .slick-next {
            width: 30px;
            height: 30px;
            z-index: 1;
          }

          .aboutUs-carousel .slick-prev:before, 
          .aboutUs-carousel .slick-next:before {
            font-size: 30px;
            color: white;
          }

          @media (max-width: 890px) {
            .aboutUs-carousel .slick-prev, 
            .aboutUs-carousel .slick-next {
              top: 50%;
              transform: translateY(-50%);
            }

            .aboutUs-carousel .slick-prev {
              left: 5px;
            }

            .aboutUs-carousel .slick-next {
              right: 5px;
            }

          }
        `}
      </style>
      <div className="absolute inset-x-0 top-0 h-[190px] bg-gradient-to-b from-black to-transparent -z-10"></div>
      <div className="px-5 sm:px-20 bg-transparent mx-auto py-0 text-center">
        <motion.h2

        className="text-4xl md:text-6xl font-bold mb-6 sm:text-left pt-[158px] text-[#FCFCFC] text-center select-none">Conocenos</motion.h2>
        <motion.p 
                  initial={{opacity: 0, y:-20}}
                  whileInView={{opacity: 1, y:0}}
                  viewport={{once: true}}
                  transition={{delay: 0.5, stiffness: 100, type:'tween'}}
        className="sm:text-left pb-20 text-[#FCFCFC] max-w-[900px] text-l 2xl:text-xl leading-tight font-light text-center select-none">
          EDICI es una empresa abocada a tareas de Ingeniería Civil, ubicada en la ciudad de Paraná, Entre Ríos, Argentina. Realizamos asesoramiento, cálculo estructural y dirección de obra en todo el país. Nos caracterizamos por nuestra responsabilidad y cumplimiento, ocupándonos en tener un trato personalizado y constante con nuestros clientes. Contamos con un equipo de profesionales dedicados a brindar soluciones innovadoras y de alta calidad.
        </motion.p>
        <motion.div         
                  initial={{opacity: 0, y:-20}}
                  whileInView={{opacity: 1, y:0}}
                  viewport={{once: true}}
                  transition={{delay: 0.6, stiffness: 100, type:'spring'}}
        className="hidden lg:block m-auto">
          <div className="grid xl:grid-cols-4 gap-0 md:gap-5 md:grid-cols-3 sm:grid-cols-2 grid-cols-1">
            {teamMembers.map((member, index) => (
              <div key={index} className="bg-[#FCFCFC] shadow-lg rounded-lg rounded-t-xl max-w-[316px] h-[455px]">
                <img src={member.image} alt={member.name} title={member.name} className="w-full object-cover rounded-t-xl mb-4" />
                <h3 className="text-2xl pt-5 font-bold m-auto text-center">{member.name}</h3>
                <p className='text-xl m-auto text-center py-3'>{member.title}</p>
                <p className="m-auto text-xl text-center max-w-44 pb-10">{member.education}</p>
                <a href={member.linkedin} 
                  title="Linkedin-button"
                  target="_blank" 
                  rel="noopener noreferrer"
                  className="text-[#FCFCFC] m-auto flex justify-center items-center bg-blue-400 rounded-full p-3 w-12 h-12 text-2xl"><FaLinkedin/></a> 
              </div>
            ))}
          </div>
        </motion.div>
        <div className="lg:hidden block">
          <Slider {...settings}>
            {teamMembers.map((member, index) => {
              return (
                <div key={index} className='mb-10 sm:mb-0 relative bg-[#FCFCFC] shadow-lg rounded-lg rounded-t-xl max-w-[254px] h-[400px]'>
                  <img src={member.image} alt={member.name} title={member.name} className="w-full object-cover rounded-t-xl mb-4" />
                  <h3 className="text-2xl pt-5 font-bold m-auto text-center">{member.name}</h3>
                  <p className='text-xl m-auto text-center py-3'>{member.title}</p>
                  <p className="m-auto text-xl text-center max-w-44 pb-5">{member.education}</p>
                  <a href={member.linkedin} 
                  target="_blank" 
                  rel="noopener noreferrer"
                  title="Linkedin-button" 
                  className="text-[#FCFCFC] m-auto flex justify-center items-center bg-blue-400 rounded-full p-3 w-12 h-12 text-2xl"><FaLinkedin/></a>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 h-[160px] bg-gradient-to-t from-black to-transparent -z-10"></div>
    </div>
  );
};

export default AboutUs;
