import React, {useState} from 'react'
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/solid'
import brandLogo from '../assets/BrandLogo.svg'
import '../index.css'


const Navbar = () => {
    const [nav, setNav] = useState(false)
    const handleClick = () => setNav(!nav)


  return (
    <div className='xl:px-[128px] px-0 sm:px-5 lg:px-10 md:px-5 w-screen z-10 py-4 md:py-6 lg:py-4 bg-[#00040D] fixed dorp-shadlow-lg'>
        <div className='px-5 sm:px-20 flex justify-between items-center w-full h-full'>
            <div className='flex items-center w-full'>
                <a title='edici-logo' href='#home'>
            <img src={brandLogo} alt="Brand Logo" title="Edici-logo" className='w-24 md:w-32 lg:w-[130px] mr-4' />
            </a>
            <ul className='hidden md:flex text-[#FCFCFC] font-bold 2xl:text-[16px] md:text[16px] text-xs ml-auto select-none z-50'>
            <li><a title='navbar-home' href="#home" className="block py-0 hover:text-[#57AFDF]" onClick={handleClick}>INICIO</a></li>
            <li><a title='navbar-aboutus' href="#AboutUs" className="block py-0 hover:text-[#57AFDF]" onClick={handleClick}>NOSOTROS</a></li>
            <li><a title='navbar-services' href="#Services" className="block py-0 hover:text-[#57AFDF]" onClick={handleClick}>SERVICIOS</a></li>
            <li><a title='navbar-metbim' href="#MetBim" className="block py-0 hover:text-[#57AFDF]" onClick={handleClick}>METODOLOGIA BIM</a></li>
            <li><a title='navbar-proyects' href="#FeaturedProyects" className="block py-0 hover:text-[#57AFDF]" onClick={handleClick}>PROYECTOS</a></li>
            <li><a title='navbar-contact' href="#Contact" className="block py-0 hover:text-[#57AFDF]" onClick={handleClick}>CONTACTO</a></li>
            </ul>
            </div>
            <div className="md:hidden" onClick={handleClick}>
            {!nav ? (
                <Bars3Icon className="size-8 text-[#FCFCFC]" />
            ) : (
                <XMarkIcon className="size-11 text-[#FCFCFC] absolute top-16 right-8 z-10 bg-[#414141] rounded-full p-2" />
            )}
            </div>
        </div>
        <div className={!nav ? 'hidden' : 'absolute bg-[#00040D]/80 w-full h-screen px-8 py-8 text-[#FCFCFC] font-bold text-xl md:hidden text-center select-none pt-24'}>
        <ul className={!nav ? 'hidden' : 'absolute bg-[#151515] w-[80%] h-[55%] rounded-lg mx-auto px-8 py-5 my-auto text-[#FCFCFC] font-bold text-xl md:hidden text-center select-none z-50 -space-y-5'}>
            <li><a title='navbar-home' href="#home" className="block pb-2" onClick={handleClick}>INICIO</a></li>
            <li><a title='navbar-aboutus' href="#AboutUs" className="block py-2" onClick={handleClick}>NOSOTROS</a></li>
            <li><a title='navbar-services' href="#Services" className="block py-2" onClick={handleClick}>SERVICIOS</a></li>
            <li><a title='navbar-metbim' href="#MetBim" className="block py-2" onClick={handleClick}>METODOLOGIA BIM</a></li>
            <li><a title='navbar-proyects' href="#FeaturedProyects" className="block py-2" onClick={handleClick}>PROYECTOS</a></li>
            <li><a title='navbar-contact' href="#Contact" className="block py-2" onClick={handleClick}>CONTACTO</a></li>
            </ul>
        </div>
    </div>
  )
}

export default Navbar