import React, { useState, useRef, useEffect } from 'react';
import HCaptcha from '@hcaptcha/react-hcaptcha';
import whatsappIcon from '../assets/contact_whatsapp.svg';
import mailIcon from '../assets/contact_email.svg';
import locationIcon from '../assets/contact_location.svg';
import '../styles.css';
import { motion } from 'framer-motion';


function ContactSection() {
  return (
    <div id="Contact" className="flex justify-center items-center min-h-screen bg-[url('assets/background_contactUs.jpg')] bg-cover bg-center text-[#FCFCFC] sm:px-20 px-5 z-0 w-full relative overflow-hidden">
      <div className="absolute inset-x-0 top-0 h-[190px] bg-gradient-to-b from-black to-transparent -z-10"></div>
      <div className='sm:py-0 py-10 relative'>
        <div className="bg-[#151515] bg-[url('assets/form-card-logo.png')] sm:p-10 p-0 rounded-[10px] shadow-lg max-w-full w-full flex flex-wrap sm:pt-16 pt-8 sm:pb-16 pb-8 sm:px-16 px-4">
          <div className="w-full md:w-1/2 mb-6 md:mb-0">
            <motion.h2 
                        initial={{opacity: 0, y:-20}}
                        whileInView={{opacity: 1, y:0}}
                        viewport={{once: true}}
                        transition={{delay: 0.4, stiffness: 100, type:'tween'}}
            className="sm:text-5xl text-4xl sm:text-left mx-4 sm:mx-0 text-center font-bold mb-6 sm:max-w-[60%] max-w-full select-none">Contactate con nosotros</motion.h2>
            <motion.ul
                        initial={{opacity: 0, y:-20}}
                        whileInView={{opacity: 1, y:0}}
                        viewport={{once: true}}
                        transition={{delay: 0.5, stiffness: 100, type:'tween'}}
            className="mb-8 mx-4 sm:mx-0 sm:-ml-5 sm:-space-y-5 space-y-2">
              <li className="flex items-center">
                <img src={whatsappIcon} title="wsp-icon" className="text-[#295186] mr-5" alt="dataIcon" />
                <a title="whatsapp-href" data-clickid='wsp-contact' href="https://wa.me/+543434387343?text=Hola%20quiero%20hacer%C2%A0una%C2%A0consulta">+54 9 3434 38-7343</a>
              </li>
              <li className="flex items-center">
                <img src={mailIcon} title="mail-icon" className="text-[#295186] mr-5" alt="dataIcon" />
                <a title="email-href" href="#Contact">edici.ingenieria@gmail.com</a>
              </li>
              <li className="flex items-center">
                <img src={locationIcon} title="location-icon" className="text-[#295186] mr-5" alt="dataIcon" />
                <a title="location-href" href="https://maps.app.goo.gl/N5tqTDuvvW6sfSkk7" target="_blank" rel="noopener noreferrer">Paraná, Entre Ríos, Argentina</a>
              </li>
            </motion.ul>
          </div>
          <div className="w-full md:w-1/2">
            <ContactForm />
          </div>
        </div>
      </div>
      <div className="absolute inset-x-0 bottom-0 h-[160px] bg-gradient-to-t from-black to-transparent -z-10"></div>
    </div>
  );
}

const ContactForm = () => {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    phone: '',
    city: '',
    message: '',
    'h-captcha-response': ''
  });
  const [formMessage, setFormMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  const hcaptchaRef = useRef(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleCaptchaChange = (token) => {
    setFormData((prevData) => ({
      ...prevData,
      'h-captcha-response': token
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData['h-captcha-response']) {
      setFormMessage('Por favor, completa el captcha!');
      return;
    }

    setLoading(true);
    setFormMessage('Enviando...');

    const formDataWithKey = { ...formData, access_key: process.env.REACT_APP_ACCESS_KEY };
    const formDataObject = new FormData();
    for (const [key, value] of Object.entries(formDataWithKey)) {
      formDataObject.append(key, value);
    }

    try {
      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: formDataObject
      });

      const result = await response.json();
      if (result.success) {
        setFormMessage('Formulario enviado exitosamente');
        hcaptchaRef.current.resetCaptcha();

        // Emitir un evento personalizado para Google Tag Manager
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({ event: 'formSubmissionSuccess' });
      } else {
        setFormMessage(result.message || 'Error al enviar el formulario');
      }
    } catch (error) {
      setFormMessage('Error al enviar el formulario');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <form className="flex flex-col space-y-3 px-5 sm:px-0" onSubmit={handleSubmit}>
      <motion.input
                              initial={{opacity: 0, y:-20}}
                              whileInView={{opacity: 1, y:0}}
                              viewport={{once: true}}
                              transition={{delay: 0.3, stiffness: 100, type:'tween'}}
        type="text"
        name="fullname"
        placeholder="Nombre completo *"
        value={formData.fullname}
        onChange={handleChange}
        className="bg-[#343333] text-[#8D8D8D] p-2 pl-4 w-full rounded-lg border-[1px] border-[#8D8D8D]"
      />
      <motion.input
                              initial={{opacity: 0, y:-20}}
                              whileInView={{opacity: 1, y:0}}
                              viewport={{once: true}}
                              transition={{delay: 0.4, stiffness: 100, type:'tween'}}
        type="email"
        name="email"
        placeholder="Email *"
        value={formData.email}
        onChange={handleChange}
        className="bg-[#343333] text-[#8D8D8D] p-2 pl-4 w-full rounded-lg border-[1px] border-[#8D8D8D]"
      />
      <motion.input
                              initial={{opacity: 0, y:-20}}
                              whileInView={{opacity: 1, y:0}}
                              viewport={{once: true}}
                              transition={{delay: 0.5, stiffness: 100, type:'tween'}}
        type="number"
        name="phone"
        placeholder="Teléfono"
        value={formData.phone}
        onChange={handleChange}
        className="bg-[#343333] text-[#8D8D8D] appearance-none p-2 pl-4 w-full rounded-lg border-[1px] border-[#8D8D8D]"
      />
      <motion.input
                              initial={{opacity: 0, y:-20}}
                              whileInView={{opacity: 1, y:0}}
                              viewport={{once: true}}
                              transition={{delay: 0.6, stiffness: 100, type:'tween'}}
        type="text"
        name="city"
        placeholder="Ciudad"
        value={formData.city}
        onChange={handleChange}
        className="bg-[#343333] text-[#8D8D8D] p-2 pl-4 w-full rounded-lg border-[1px] border-[#8D8D8D]"
      />
      <motion.textarea
                              initial={{opacity: 0, y:-20}}
                              whileInView={{opacity: 1, y:0}}
                              viewport={{once: true}}
                              transition={{delay: 0.7, stiffness: 100, type:'tween'}}
        name="message"
        rows="3"
        placeholder="Cuéntanos sobre lo que necesitas *"
        value={formData.message}
        onChange={handleChange}
        className="bg-[#343333] text-[#8D8D8D] p-2 pl-4 w-full rounded-lg h-32 border-[1px] border-[#8D8D8D]"
      ></motion.textarea>
      <div className={isMobile ? "mb-4 mx-auto" : "hidden md:block absolute xl:bottom-[64px] lg:bottom-[64px] md:bottom-[64px] left-14 mt-4"}>
        <HCaptcha
          sitekey="50b2fe65-b00b-4b9e-ad62-3ba471098be2"
          reCaptchaCompat={false}
          onVerify={handleCaptchaChange}
          ref={hcaptchaRef}
          hl="es"
        />
      </div>
      <motion.button
      initial={{ scale: 1, opacity: 0 }} 
      whileInView={{
        opacity: 1,
        scale: [1, 1.1, 1, 1.1, 1], 
      }}
      transition={{
        duration: 2, 
        ease: "easeInOut", 
        repeat: false, 
        delay: 0.7 
      }}
      viewport={{once: true}}

        type="submit"
        className="bg-[#57AFDF] hover:bg-[#295186] transform active:bg-[#CFE4FF] text-[#FCFCFC] p-2 w-full rounded border-[1px] border-[#295186]"
        disabled={loading}
      >
        {loading ? 'Enviando...' : 'Consultar'}
      </motion.button>
      {formMessage && <div className="mt-4 text-center text-[#57AFDF]">{formMessage}</div>}
    </form>
  );
};

export default ContactSection;
