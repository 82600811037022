import Navbar from './components/Navbar';
import Hero from './components/Hero';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import MetBIM from './components/MetBIM';
import FeaturedProyects from './components/FeaturedProyects';
import Contact from './components/Contact'
import Footer from './components/Footer';
import WhatsAppButton from './components/Whatsapp';

function App() {
  return (
<>
    <Navbar/>
    <Hero/>
    <Services/>
    <MetBIM/>
    <FeaturedProyects/>
    <AboutUs/>
    <Contact/>
    <Footer/>
    <WhatsAppButton/>
    </>
  );
}

export default App;
