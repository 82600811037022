import React from 'react'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';
import gestCentralizada from '../assets/gestion_centralizada.svg';
import potenciacion from '../assets/potenciacion.svg';
import equiposTrabajo from '../assets/equipos_de_trabajo.svg';
import integracion from '../assets/Integracion.svg';
import visualizacion from '../assets/visualizacion.svg';
import { motion } from 'framer-motion';

const slidesData = [
  {
    title: 'Gestión Centralizada',
    description: 'El modelo BIM centraliza toda la información sobre materiales, costos y cronogramas, permitiendo tomar decisiones informadas y gestionar el proyecto de manera más eficiente.',
    icon: gestCentralizada,
  },
  {
    title: 'Potenciación',
    description: 'Colaborar con EDICI permite a tu empresa beneficiarse de nuestra amplia experiencia en la metodología BIM. Implementamos procesos que mejoran la coordinación y comunicación entre los equipos, optimizando cada fase del proyecto desde el diseño hasta la construcción.',
    icon: potenciacion,
  },
  {
    title: 'Equipos de trabajo',
    description: 'Al trabajar con nosotros, tu equipo tendrá acceso a formación y prácticas avanzadas en BIM, lo que les permitirá adoptar y dominar esta metodología de manera más efectiva. Esto resulta en una mayor productividad, reducción de errores y una mejor gestión de los recursos.',
    icon: equiposTrabajo,
  },
  {
    title: 'Integración',
    description: 'La metodología BIM facilita la integración de todas las fases del proyecto en un modelo digital unificado. Esto no solo mejora la colaboración entre todos los involucrados, sino que también permite una planificación y gestión más precisa y eficiente.',
    icon: integracion,
  },
  {
    title: 'Visualización',
    description: 'Gracias al modelado 3D, podemos visualizar el proyecto desde el inicio, identificando y resolviendo problemas potenciales antes de que se conviertan en inconvenientes costosos durante la construcción.',
    icon: visualizacion,
  },
];

const CustomArrow = ({ className, style, onClick }) => (
  <div
    className={className}
    style={{ ...style, display: 'hidden', background: 'transparent', borderRadius: '50%' }}
    onClick={onClick}
  />
);

const MetBIM = () => {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    nextArrow: <CustomArrow />,
    prevArrow: <CustomArrow />,
    responsive: [
      {
        breakpoint: 1800,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 1480,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 890,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
    ],
    customPaging: i => (
      <div
        style={{
          width: '10px',
          height: '10px',
          marginTop: '16px',
          borderRadius: '50%',
          backgroundColor: '#FCFCFC',
        }}
      />
    ),
  };

  return (
    <div id="MetBim" className="met-bim-carousel bg-[#00040D] bg-[url('assets/background_metbim.jpg')] bg-cover bg-center bg-no-repeat h-[1024px] xl:px-[128px] px-0 sm:px-5 md:px-5 lg:px-10 w-full relative z-0">
      <style>
        {`
          .met-bim-carousel .slick-prev, 
          .met-bim-carousel .slick-next {
            width: 30px;
            height: 30px;
            z-index: 1;
          }

          .met-bim-carousel .slick-prev:before, 
          .met-bim-carousel .slick-next:before {
            font-size: 30px;
            color: white;
          }
            
          .met-bim-carousel .slick-prev {
            left: -50px;
          }

          .met-bim-carousel .slick-next {
            right: -40px;
          }

          @media (max-width: 890px) {
            .met-bim-carousel .slick-prev, 
            .met-bim-carousel .slick-next {
              top: 50%;
              transform: translateY(-50%);
            }

            .met-bim-carousel .slick-prev {
              left: -20px;
            }

            .met-bim-carousel .slick-next {
              right: -10px;
            }
          }
        `}
      </style>
      <div className="absolute inset-x-0 top-0 h-[190px] bg-gradient-to-b from-black to-transparent -z-10"></div>
      <div className="sm:px-20 px-5 bg-transparent mx-auto py-0">
        <motion.h2 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.4, stiffness: 100, type: 'tween' }}
          className="text-6xl font-bold mb-6 text-center sm:pt-36 text-[#FCFCFC] select-none"
        >
          Metodología BIM
        </motion.h2>
        <motion.p       
        initial={{opacity: 0, y:-20}}
        whileInView={{opacity: 1, y:0}}
        viewport={{once: true}}
       transition={{delay: 0.5, stiffness: 100, type:'tween'}}
        className="text-left sm:pb-36 text-[#FCFCFC] max-w-[800px] text-l 2xl:text-xl m-auto leading-tight font-light select-none">
          En EDICI, aplicamos la metodología Building Information Modeling (BIM) para transformar y potenciar cada etapa de los proyectos de nuestros clientes. Nuestra experiencia y enfoque avanzado en BIM no solo asegura la integración y eficiencia de los proyectos, sino que también proporciona a nuestros clientes las herramientas y conocimientos necesarios para fortalecer sus propios equipos de trabajo.
        </motion.p>
        <motion.div 
          initial={{ opacity: 0, y: -20 }}
          whileInView={{ opacity: 1, y: 0 }}
          viewport={{ once: true }}
          transition={{ delay: 0.6, stiffness: 100, type: 'tween' }}
          className="sm:-mt-16 mt-20"
        >
          <Slider {...settings}>
            {slidesData.map((slide, index) => (
              <div key={index} className="mx-auto xl:ml-0 sm:-ml-3 ml-0 pl-5 pt-5 h-[500px] w-[400px]">
                <div className="relative bg-[#FCFCFC] rounded-lg shadow-lg p-6 sm:max-w-[324px] h-[471px] sm:min-w-[324px] max-w-[280px]">
                  {/* Fondo celeste */}
                  <div className="absolute -top-2 -right-2 w-32 h-32 bg-[#57AFDF] rounded-lg -z-10"></div>
                  <div className="absolute -bottom-2 -left-2 w-32 h-32 bg-[#57AFDF] rounded-lg -z-10"></div>
                  <div className='w-[80px] h-48 rounded-t-lg m-auto -mb-20 -mt-5'>
                  <img src={slide.icon} alt="imagen" title={slide.title} className="mt-10 shadow-xl rounded-2xl" />
                  </div>
                  <h3 className="text-xl font-semibold m-auto text-center mb-0">{slide.title}</h3>
                  <p className="px-5 mx-auto sm:w-auto w-[250px] -ml-2 text-left py-5 font-light leading-tight">{slide.description}</p>
                </div>
              </div>
            ))}
          </Slider>
        </motion.div>
      </div>
      <div className="absolute inset-x-0 bottom-0 h-[160px] bg-gradient-to-t from-black to-transparent -z-10"></div>
    </div>
  );
};

export default MetBIM;